import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Divider, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import proxy from '../../api/axios';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '12px',
};

const inputStyle = {
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
};

const buttonStyle = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: '8px',
  boxShadow: '0px 4px 20px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  marginTop: '16px',
};

const dividerStyle = {
  my: 3,
  position: 'relative',
  '&::before, &::after': {
    content: '""',
    display: 'block',
    width: '40%',
    height: '1px',
    backgroundColor: '#e0e0e0',
  },
  '&::before': { float: 'left' },
  '&::after': { float: 'right' },
};

const ForgetPasswordModal = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const response = await proxy.post('/auth/forgot-password', { email });
      if (response.data.success) {
        setSuccess('An email has been sent to reset your password.');
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('Failed to send reset password email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 16, top: 16, color: 'grey.500' }}
          >
            <CloseIcon />
          </IconButton>
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 3 }}>
          Forgot Your Password?
        </Typography>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <TextField
          label="Enter your email"
          fullWidth
          margin="normal"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={inputStyle}
        />

        <Button
          fullWidth
          variant="contained"
          sx={buttonStyle}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send Reset Link'}
        </Button>

        <Divider sx={dividerStyle} />

        <Button
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default ForgetPasswordModal;
