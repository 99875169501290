import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Tabs, Tab, Divider, Alert, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/userSlice';
import { toast } from 'react-toastify';
import { hideAuth } from '../../redux/authSlice';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Import the ForgotPasswordModal (Assuming you have this component already)
import ForgotPasswordModal from './ForgotPasswordModal';
import proxy from '../../api/axios';

const modalStyle = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '12px',
};

const inputStyle = {
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
};

const buttonStyle = {
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  borderRadius: '8px',
  boxShadow: '0px 4px 20px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  marginTop: '16px',
};

const googleButtonStyle = {
  background: '#4285F4',
  borderRadius: '8px',
  color: 'white',
  padding: '10px 20px',
  marginTop: '16px',
  textTransform: 'none',
  width: '100%',
};

const dividerStyle = {
  my: 3,
  position: 'relative',
  '&::before, &::after': {
    content: '""',
    display: 'block',
    width: '40%',
    height: '1px',
    backgroundColor: '#e0e0e0',
  },
  '&::before': { float: 'left' },
  '&::after': { float: 'right' },
};

const AuthModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const openingRequest = useSelector((state) => state.auth.isOpen);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({ email: '', password: '', name: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setError('');
    setSuccess('');
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    console.log('Google Login Success:', credentialResponse);
    try {
      const response = await proxy.post('/auth/google-login', {
        token: credentialResponse.credential,
      });
      if (response.data.success) {
        setSuccess('Login successful!');
        onClose(); // Close modal after success
      }
    } catch (error) {
      setError('Google Login Failed');
    }
  };

  const handleGoogleFailure = (error) => {
    setError('Google Login Failed');
    console.log('Google Login Failed:', error);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const { data } = await proxy.post('/auth/login', {
        email: formData.email,
        password: formData.password,
      });
      if (data.success) {
        setSuccess('Login successful!');
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        dispatch(login(data.user));
        dispatch(hideAuth());
        onClose(); // Close modal after success
        toast.success('Welcome Back, ' + data.user.name);
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError('Login failed, please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const response = await proxy.post('/auth/signup', {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      });
      if (response.data.success) {
        setSuccess('Sign up successful!');
        onClose(); // Close modal after success
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message || 'Sign up failed');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setForgotPasswordOpen(true);
    onClose(); // Close auth modal when forgot password is opened
  };

  const handleModalClose = () => {
    setFormData({ email: '', password: '', name: '' });
    dispatch(hideAuth());
    onClose();
  }

  useEffect(() => {
    setFormData({ email: '', password: '', name: '' });
    setError('');
  }, []);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <GoogleOAuthProvider clientId={'YOUR_CLIENT_ID'}>
      <Modal open={open || openingRequest} onClose={onClose}>
        <Box sx={modalStyle}>
          {/* Close Icon */}
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{ position: 'absolute', right: 16, top: 16, color: 'grey.500' }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 3 }}>
            {tabValue === 0 ? 'Welcome Back!' : 'Create Your Account'}
          </Typography>

          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            centered
            textColor="primary"
            indicatorColor="primary"
            sx={{ mb: 3 }}
          >
            <Tab label="Login" />
            <Tab label="Sign Up" />
          </Tabs>

          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

          {tabValue === 0 ? (
            <>
              <TextField
                label="Email"
                fullWidth
                margin="normal"
                name="email"
                value={formData.email}
                onChange={handleChange}
                sx={inputStyle}
              />
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                margin="normal"
                name="password"
                value={formData.password}
                onChange={handleChange}
                sx={inputStyle}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                fullWidth
                variant="contained"
                sx={buttonStyle}
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? 'Logging in...' : 'Login'}
              </Button>

              {/* Forgot Password Link */}
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                <Link href="#" onClick={handleForgotPassword}>
                  Forgot Password?
                </Link>
              </Typography>
            </>
          ) : (
            <>
              <TextField
                label="Name"
                fullWidth
                margin="normal"
                name="name"
                value={formData.name}
                onChange={handleChange}
                sx={inputStyle}
              />
              <TextField
                label="Email"
                fullWidth
                margin="normal"
                name="email"
                value={formData.email}
                onChange={handleChange}
                sx={inputStyle}
              />
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                margin="normal"
                name="password"
                value={formData.password}
                onChange={handleChange}
                sx={inputStyle}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                fullWidth
                variant="contained"
                sx={buttonStyle}
                onClick={handleSignUp}
                disabled={loading}
              >
                {loading ? 'Signing up...' : 'Sign Up'}
              </Button>
            </>
          )}

          {/* <Divider sx={dividerStyle}>OR</Divider>

          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onFailure={handleGoogleFailure}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                sx={googleButtonStyle}
              >
                Continue with Google
              </Button>
            )}
          /> */}
        </Box>
      </Modal>

      {/* Forgot Password Modal */}
      <ForgotPasswordModal open={forgotPasswordOpen} onClose={() => setForgotPasswordOpen(false)} />
    </GoogleOAuthProvider>
  );
};

export default AuthModal;
