import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';
import { Facebook, Twitter, GitHub, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#2D2D2D',
        color: '#fff',
        py: 4,
        mt: 'auto',
        textAlign: 'center',
      }}
    >
      <Grid container spacing={2} sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body2">
            CrafterHack is your ultimate coding platform for challenges, contests, and collaborative learning. Join our community and enhance your coding skills!
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Typography variant="body2">
            <Link href="/problems" color="inherit" underline="hover">Problems</Link><br />
            <Link href="/contests" color="inherit" underline="hover">Contests</Link><br />
            <Link href="/leaderboard" color="inherit" underline="hover">Leaderboard</Link><br />
            <Link href="/dsa-programs" color="inherit" underline="hover">DSA Programs</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2">
            Contact: <Link href="mailto:info@crafterhack.com" color="inherit" underline="hover">info@crafterhack.com</Link>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Follow us on social media:
          </Typography>
          <Box>
            <Link href="#" color="inherit" sx={{ mx: 1 }}>
              <Facebook />
            </Link>
            <Link href="#" color="inherit" sx={{ mx: 1 }}>
              <Twitter />
            </Link>
            <Link href="#" color="inherit" sx={{ mx: 1 }}>
              <GitHub />
            </Link>
            <Link href="#" color="inherit" sx={{ mx: 1 }}>
              <LinkedIn />
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} CrafterHack. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
