import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button, List, ListItem, Chip, CircularProgress } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import TimerIcon from '@mui/icons-material/Timer';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from '@mui/material/styles';
import proxy from '../api/axios';
import { showAuth } from '../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ContestPaper = styled(Paper)(({ theme }) => ({
  padding: '20px',
  marginBottom: '15px',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
    backgroundColor: theme.palette.background.default,
  },
  maxWidth: '1200px',
  margin: 'auto',
}));

const formatDateTime = (dateTime) => {
  const dateObj = new Date(dateTime);
  const date = dateObj.toLocaleDateString();
  const time = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  return { date, time };
};

const ContestsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInInfo = useSelector((state) => state.user.userInfo);

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const { data } = await proxy.get('/contests');
        setContests(data);
      } catch (error) {
        console.error('Error fetching contests:', error);
        toast.error('Failed to load contests');
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchContests();
  }, []);

  const handleViewResults = (contestId) => {
    if (!isLoggedIn) {
      dispatch(showAuth());
      return;
    }
    navigate(`${contestId}/result`);
  };

  const handleStartNow = async (contestId) => {
    if (!isLoggedIn) {
      dispatch(showAuth());
      return;
    }
    try {
      await proxy.post(`/contests/join`, { id: contestId });
      navigate(`/live-contest/${contestId}`);
    } catch (error) {
      console.error('Error fetching contest details:', error);
      toast.error('Could not start the contest');
    }
  };

  const isContestActive = (startTime, endTime) => {
    const currentTime = new Date();
    const contestStartTime = new Date(startTime);
    const contestEndTime = new Date(endTime);
    return contestStartTime <= currentTime && contestEndTime >= currentTime;
  };

  const isContestCompleted = (endTime) => {
    const currentTime = new Date();
    const contestEndTime = new Date(endTime);
    return contestEndTime < currentTime;
  };

  const getContestStatus = (contest) => {
    const currentTime = new Date();
    if (currentTime < new Date(contest.startTime)) {
      return 'Upcoming';
    }
    if (isContestActive(contest.startTime, contest.endTime) && contest.status!=='Already Submitted') {
      return 'In Progress';
    }
    if (isContestCompleted(contest.endTime) || contest.status==='Already Submitted') {
      return 'Completed';
    }
    return 'Unknown';
  };

  const getChipColor = (status) => {
    switch (status) {
      case 'Upcoming':
        return 'info';
      case 'In Progress':
        return 'primary';
      case 'Completed':
        return 'success';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ padding: '20px', width: '85%', margin: 'auto' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Contests
      </Typography>

      {loading ? ( // Check if loading
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <CircularProgress />
          <span style={{marginLeft: 7, color: '#1497c1'}}>Loading Contests</span>
        </Box>
      ) : (
        <List>
          {contests.map((contest) => {
            const status = getContestStatus(contest);
            return (
              <ContestPaper key={contest.id} style={{marginTop:15}}>
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding:'5px'
                  }}
                >
                  <Box sx={{ flexGrow: 1, maxWidth: '700px' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.2rem', letterSpacing: 1  }}>
                      {contest.title}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, fontSize: '0.8rem', letterSpacing: 1 }}>
                      {contest.description}
                    </Typography>

                    {/* Status Chip */}
                    <Chip
                      label={status}
                      color={getChipColor(status)}
                      sx={{ mt: 1, fontWeight: 'bold' }}
                    />
                  </Box>

                  <Box sx={{ textAlign: 'right', ml: 4 }}>
                    <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                      <EventIcon fontSize="small" /> Start: {formatDateTime(contest.startTime).date} at {formatDateTime(contest.startTime).time}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                      <EventIcon fontSize="small" /> Ends: {formatDateTime(contest.endTime).date} at {formatDateTime(contest.endTime).time}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '1rem', mb: 1 }}>
                      <TimerIcon fontSize="small" /> Duration: {contest.duration} mins
                    </Typography>

                    <Box>
                      {status === 'In Progress' && (
                        <Button onClick={() => handleStartNow(contest.id)} variant="contained" color="success" startIcon={<PlayCircleFilledWhiteIcon />}>
                          Start Now
                        </Button>
                      )}
                      {(status === 'Completed' || loggedInInfo?.role==='admin') && (
                        <Button onClick={() => handleViewResults(contest.id)} variant="outlined" color="secondary">
                          View Results
                        </Button>
                      )}
                    </Box>
                  </Box>
                </ListItem>
              </ContestPaper>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default ContestsPage;
