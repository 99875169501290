import React, { useEffect, useState } from 'react';
import {
  Box, Button, TextField, Typography, Select, MenuItem, IconButton, Modal, Checkbox, FormControlLabel, Chip
} from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DeleteIcon from '@mui/icons-material/Delete';
import MonacoEditor from '@monaco-editor/react';
import { toast } from 'react-toastify';
import { defaultCode } from '../../common/constants';
import { useParams, useNavigate } from 'react-router-dom';
import { stateFromHTML } from 'draft-js-import-html';
import { getToken } from '../../common/utils';
import proxy from '../../api/axios';

const ProblemFormPage = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const navigate = useNavigate();
  
  const [title, setTitle] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [difficulty, setDifficulty] = useState('easy');
  const [constraints, setConstraints] = useState('');
  const [inputFormat, setInputFormat] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [testCases, setTestCases] = useState([{ input: '', output: '', isSample: true }]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [level, setLevel] = useState(1); // New: Level
  const [displayOrder, setDisplayOrder] = useState(0); // New: Level

  const [section, setSection] = useState('Getting Started'); // New: Section

  const [topics, setTopics] = useState([]); // New: Topics

  const [topicInput, setTopicInput] = useState(''); // New: Topic input

  // Language wrappers
  const [wrapperCode, setWrapperCode] = useState(defaultCode);

  // Fetch problem details for editing
  useEffect(() => {
    if (slug) {
      const fetchProblemDetails = async () => {
        try {
          const response = await proxy.get(`/problems/admin/${slug}`);
          const problem = response.data;

          // Populate fields with problem details
          setTitle(problem.title);
          const contentState = stateFromHTML(problem.description);
        
          setDescription(EditorState.createWithContent(contentState));
          setDifficulty(problem.difficulty);
          setConstraints(problem.constraints);
          setInputFormat(problem.inputFormat);
          setOutputFormat(problem.outputFormat);
          setTestCases(problem.testCases || [{ input: '', output: '', isSample: true }]);
          setWrapperCode(problem.wrapperCode || defaultCode);
          setVideoLink(problem.videoLink);
          setLevel(problem.level); // Set the level
          setSection(problem.section); // Set the section
          setTopics(problem.topics || []); // Set topics
          setDisplayOrder(problem.displayOrder);
        } catch (error) {
          toast.error('Error fetching problem details');
          console.error(error);
        }
      };

      fetchProblemDetails();
    }
  }, [slug]);

  // Handlers for Test Cases
  const handleAddTestCase = () => {
    setTestCases([...testCases, { input: '', output: '', isSample: false }]);
  };

  const handleRemoveTestCase = (index) => {
    const updatedTestCases = testCases.filter((_, i) => i !== index);
    setTestCases(updatedTestCases);
  };

  const handleTestCaseChange = (index, field, value) => {
    const updatedTestCases = [...testCases];
    updatedTestCases[index][field] = value;
    setTestCases(updatedTestCases);
  };

  const handleSampleTestCaseChange = (index) => {
    const updatedTestCases = [...testCases];
    updatedTestCases[index].isSample = !updatedTestCases[index].isSample;
    setTestCases(updatedTestCases);
  };

  // Preview Handler
  const handlePreviewOpen = () => setPreviewOpen(true);
  const handlePreviewClose = () => setPreviewOpen(false);

  const getFormattedDescription = () => {
    return draftToHtml(convertToRaw(description.getCurrentContent()));
  };

  // Handle language-specific wrapper code changes
  const handleWrapperCodeChange = (language, value) => {
    setWrapperCode({
      ...wrapperCode,
      [language]: value,
    });
  };

  const handleSubmitProblem = async () => {
    const problemData = {
      title,
      description: getFormattedDescription(),
      difficulty,
      constraints,
      inputFormat,
      outputFormat,
      testCases,
      wrapperCode,
      videoLink,
      isPublished: false,
      level,
      section,
      topics,
      displayOrder
    };

    try {

      if (!title || !description){
        toast.error('Problem title and description should be there');
        return;
      } else if(testCases.filter(testCase=>testCase.input)?.length===0){
        toast.error('There should be atleast a test case added');
        return;
      }

      if (slug) {
        // Update existing problem
        await proxy.put(`/problems/${slug}`, problemData, {headers: { 'Authorization': `Bearer ${getToken()}` }});
        toast.success('Problem updated successfully');
      } else {
        // Create new problem
        await proxy.post('/problems', problemData, {headers: { 'Authorization': `Bearer ${getToken()}` }});
        toast.success('Problem created successfully');
      }
      navigate('/admin/manage-problems'); // Redirect to manage problems page after submission
    } catch (error) {
      toast.error('Error creating/updating problem');
      console.error('Error submitting problem', error);
    }
  };

  // Handlers for Topics
  const handleAddTopic = () => {
    if (topicInput.trim() && !topics.includes(topicInput.trim())) {
      setTopics([...topics, topicInput.trim()]);
      setTopicInput('');
    }
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopics(topics.filter((topic) => topic !== topicToRemove));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
  };
  const sections = [
    'Getting Started',
    'Patterns',
    'Number System',
    'Arrays',
    '2-D Array',
    'Contest'
  ]

  return (
    <Box sx={{ width: '70%', margin: 'auto', textAlign: 'left', padding: '20px' }}>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => navigate('/admin/manage-problems')} 
        sx={{ mb: 3 }}
      >
        All Problems
      </Button>
      <Typography variant="h4" gutterBottom style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {slug ? 'Edit Problem' : 'Create Problem'}
        <Box>
          <Button onClick={handlePreviewOpen} variant="outlined" sx={{ marginRight: '10px' }}>
            Preview
          </Button>
          <Button onClick={handleSubmitProblem} variant="contained" color="success">
            {slug ? 'Update Problem' : 'Save Problem'}
          </Button>
        </Box>
      </Typography>
      {/* Level, Section, and Topics in a single row */}
      <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', marginBottom: '20px' }}>
        {/* Level Selector */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            Level
          </Typography>
          <Select
            fullWidth
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            margin="normal"
          >
            <MenuItem value={1}>Level 1</MenuItem>
            <MenuItem value={2}>Level 2</MenuItem>
          </Select>
        </Box>

        {/* Section Selector */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            Section
          </Typography>
          <Select
            fullWidth
            value={section}
            onChange={(e) => setSection(e.target.value)}
            margin="normal"
          >
            {
              sections.map(section=>(
                <MenuItem key={section} value={section}>{section}</MenuItem>
              ))
            }
          </Select>
        </Box>

        {/* Topics Input */}
        <Box sx={{ flex: 2 }}>
          <Typography variant="h6" gutterBottom>
            Topics
          </Typography>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <TextField
              label="Add Topic"
              value={topicInput}
              onChange={(e) => setTopicInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleAddTopic()}
              fullWidth
            />
            <Button onClick={handleAddTopic} variant="contained">
              Add
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
            {topics.map((topic, index) => (
              <Chip
                key={index}
                label={topic}
                onDelete={() => handleRemoveTopic(topic)}
                color="primary"
                sx={{ marginBottom: '10px' }}
              />
            ))}
          </Box>
        </Box>
      </Box>
      {/* New: Order Input */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Display Order</Typography>
        <TextField
          type="number"
          fullWidth
          value={displayOrder}
          onChange={(e) => setDisplayOrder(parseInt(e.target.value, 10))}
          placeholder="Order number"
        />
      </Box>

      {/* Problem Title */}
      <TextField
        label="Problem Title"
        fullWidth
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      
      {/* Video Link */}
      <TextField
        label="Video Link"
        fullWidth
        margin="normal"
        value={videoLink}
        onChange={(e) => setVideoLink(e.target.value)}
      />

      {/* Problem Description */}
      <Typography variant="h6" gutterBottom>
        Problem Description
      </Typography>
      <Box sx={{ marginBottom: '20px' }}>
        <Editor
          editorState={description}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setDescription}
          editorStyle={{ minHeight: '200px', border: '1px solid lightgrey', padding: '0 10px' }} // Increased height
        />
      </Box>

      {/* Difficulty Selector */}
      <Typography variant="h6" gutterBottom>
        Difficulty
      </Typography>
      <Select
        fullWidth
        value={difficulty}
        onChange={(e) => setDifficulty(e.target.value)}
        margin="normal"
      >
        <MenuItem value="easy">Easy</MenuItem>
        <MenuItem value="medium">Medium</MenuItem>
        <MenuItem value="hard">Hard</MenuItem>
      </Select>

      {/* Constraints Field */}
      <TextField
        label="Constraints"
        fullWidth
        multiline
        rows={3}
        margin="normal"
        value={constraints}
        onChange={(e) => setConstraints(e.target.value)}
      />

      {/* Input Format Field */}
      <TextField
        label="Input Format"
        fullWidth
        multiline
        rows={3}
        margin="normal"
        value={inputFormat}
        onChange={(e) => setInputFormat(e.target.value)}
      />

      {/* Output Format Field */}
      <TextField
        label="Output Format"
        fullWidth
        multiline
        rows={3}
        margin="normal"
        value={outputFormat}
        onChange={(e) => setOutputFormat(e.target.value)}
      />

      {/* Test Cases Section */}
      <Typography variant="h6" gutterBottom>
        Test Cases
      </Typography>
      {testCases.map((testCase, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <TextField
            label={`Input #${index + 1}`}
            multiline
            rows={2}
            fullWidth
            value={testCase.input}
            onChange={(e) => handleTestCaseChange(index, 'input', e.target.value)}
            margin="normal"
            sx={{ marginRight: '10px' }}
          />
          <TextField
            label={`Output #${index + 1}`}
            multiline
            rows={2}
            fullWidth
            value={testCase.output}
            onChange={(e) => handleTestCaseChange(index, 'output', e.target.value)}
            margin="normal"
            sx={{ marginRight: '10px' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={testCase.isSample}
                onChange={() => handleSampleTestCaseChange(index)}
              />
            }
            label="Sample"
          />
          <IconButton onClick={() => handleRemoveTestCase(index)} color="error" sx={{ marginTop: '10px' }}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button onClick={handleAddTestCase} variant="contained" sx={{ marginBottom: '20px' }}>
        Add Test Case
      </Button>

      {/* Wrapper Code Section for each language */}
      

      <Typography variant="h6" gutterBottom>
        Initial Code (Java)
      </Typography>
      <MonacoEditor
        height="200px"
        language="java"
        value={wrapperCode.java}
        onChange={(value) => handleWrapperCodeChange('java', value)}
      />

      <Typography variant="h6" gutterBottom>
        Initial Code (Python)
      </Typography>
      <MonacoEditor
        height="200px"
        language="python"
        value={wrapperCode.python}
        onChange={(value) => handleWrapperCodeChange('python', value)}
      />

      <Typography variant="h6" gutterBottom>
        Initial Code (C++)
      </Typography>
      <MonacoEditor
        height="200px"
        language="cpp"
        value={wrapperCode.cpp}
        onChange={(value) => handleWrapperCodeChange('cpp', value)}
      />

      <Typography variant="h6" gutterBottom>
        Initial Code (JavaScript)
      </Typography>
      <MonacoEditor
        height="200px"
        language="javascript"
        value={wrapperCode.javascript}
        onChange={(value) => handleWrapperCodeChange('javascript', value)}
      />

      
      {/* Modal for Preview */}
      <Modal
        open={previewOpen}
        onClose={handlePreviewClose}
        aria-labelledby="preview-modal-title"
        aria-describedby="preview-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" id="preview-modal-title">Problem Preview</Typography>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: getFormattedDescription() }} />
          <Typography variant="subtitle1">Difficulty: {difficulty}</Typography>
          <Typography variant="subtitle1">Constraints:</Typography>
          <Typography variant="body1">{constraints}</Typography>
          <Typography variant="subtitle1">Input Format:</Typography>
          <Typography variant="body1">{inputFormat}</Typography>
          <Typography variant="subtitle1">Output Format:</Typography>
          <Typography variant="body1">{outputFormat}</Typography>
          <Typography variant="subtitle1">Test Cases:</Typography>
          {testCases.map((testCase, index) => (
            <Typography key={index} variant="body2">
              {`Input: ${testCase.input}, Output: ${testCase.output}, Sample: ${testCase.isSample ? 'Yes' : 'No'}`}
            </Typography>
          ))}
        </Box>
      </Modal>
    </Box>
  );
};

export default ProblemFormPage ;
