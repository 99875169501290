// src/components/ThemeSwitcher.js
import { Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from '../redux/themeSlice';

const ThemeSwitcher = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  const handleThemeChange = () => {
    dispatch(toggleTheme());
  }; 

  return (
    <Switch
      checked={isDarkMode}
      onChange={handleThemeChange}
      color="default"
      inputProps={{ 'aria-label': 'theme switcher' }}
    />
  );
};

export default ThemeSwitcher;
