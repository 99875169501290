import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TableContainer,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import proxy from '../api/axios';
import { toast } from 'react-toastify';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: '80%', // Set maximum width to 80%
  margin: '0 auto', // Center the box
  padding: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '15px',
  boxShadow: '0 6px 30px rgba(0, 0, 0, 0.1)',
  animation: 'fadeIn 0.5s ease-in-out',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:last-child': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const ContestResult = () => {
  const { contestId } = useParams();
  const [contest, setContest] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContestResults = async () => {
      try {
        setIsLoading(true);
        const { data } = await proxy.get(`/contests/${contestId}/result`);
        setContest(data.contest);
        setParticipants(data.participants);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching contest results:', error);
        toast.error('Could not load contest results.');
      }
    };

    fetchContestResults();
  }, [contestId]);

  const formatDateTime = (datetime) => {
    return new Date(datetime).toLocaleString();
  };

  if (isLoading) {
    return (
      <StyledBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </StyledBox>
    );
  }

  return (
    <StyledBox sx={{ mb: 4 }}>
      {contest && (
        <StyledBox sx={{ mb: 4 }}>
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{contest.title}</Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary', mt: 1 }}>{contest.description}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1"><strong>Duration:</strong> {contest.duration} minutes</Typography>
                <Typography variant="body1"><strong>Start Time:</strong> {formatDateTime(contest.startTime)}</Typography>
                <Typography variant="body1"><strong>End Time:</strong> {formatDateTime(contest.endTime)}</Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell><Typography variant="h6">Rank</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="h6">Participant</Typography></StyledTableCell>
                    <StyledTableCell><Typography variant="h6">Score ({contest.problems.length * 10})</Typography></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participants.length > 0 ? (
                    participants.map((participant, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell> {/* Display rank */}
                        <TableCell>{participant.user.name}</TableCell>
                        <TableCell>{participant.score}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant="body1" sx={{ textAlign: 'center', py: 2 }}>
                          No participants yet.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </StyledBox>
      )}
    </StyledBox>
  );
};

export default ContestResult;
