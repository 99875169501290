import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, IconButton, InputBase, Divider, Chip, CircularProgress, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import proxy from '../api/axios';
import { useTheme } from '@emotion/react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { shuffleArray } from '../common/utils';

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '14px',
  borderRadius: '4px',
  textDecoration: 'none',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    transform: 'scale(1.005)',
  },
}));

const ProblemsPage = () => {
  const theme = useTheme();
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedDifficulties, setSelectedDifficulties] = useState([]); // State for difficulty
  const [uniqueTopics, setUniqueTopics] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const difficulties = ['easy', 'medium', 'hard']; // Difficulty levels

  const toPascalCase = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const response = await proxy.get('/problems');
        const data = response.data;
        const shuffledProblems = shuffleArray(data);
        setProblems(shuffledProblems);
        setFilteredProblems(shuffledProblems);

        const topicsSet = new Set();
        shuffledProblems?.forEach(problem => {
          problem.topics.forEach(topic => topicsSet.add(topic));
        });
        setUniqueTopics(Array.from(topicsSet));
      } catch (error) {
        setError('Error fetching problems');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProblems();
  }, []);

  useEffect(() => {
    const filtered = problems.filter(problem => {
      const matchesSearch = problem.title.toLowerCase().includes(searchInput.toLowerCase());
      const matchesTopic = selectedTopics.length === 0 || selectedTopics.some(topic =>
        problem.topics.includes(topic)
      );
      const matchesDifficulty = selectedDifficulties.length === 0 || selectedDifficulties.includes(problem.difficulty);
      return matchesSearch && matchesTopic && matchesDifficulty;
    });
    setFilteredProblems(filtered);
  }, [searchInput, selectedTopics, selectedDifficulties, problems]);

  const handleTopicClick = (topic) => {
    setSelectedTopics((prev) =>
      prev.includes(topic) ? prev.filter(t => t !== topic) : [...prev, topic]
    );
  };

  const handleDifficultyClick = (difficulty) => {
    setSelectedDifficulties((prev) =>
      prev.includes(difficulty) ? prev.filter(d => d !== difficulty) : [...prev, difficulty]
    );
  };

  const handlePickForMe = () => {
    // Filter the problems to include only those that are unsolved
    const unsolvedProblems = filteredProblems.filter(problem => !problem.isSolved);
  
    if (unsolvedProblems.length > 0) {
      const randomProblem = unsolvedProblems[Math.floor(Math.random() * unsolvedProblems.length)];
      navigate(`/problems/${randomProblem.slug}`);
    } else {
      alert('You do not have any problem left to solve!');
    }
  };
  

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
        Problems
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 3, flexGrow: 1 }}>
        {/* Sticky Difficulty Section */}
        { !isMobile && <Box sx={{ flexBasis: { xs: '100%', sm: '25%' }, position: 'sticky', top: 0, alignSelf: 'flex-start' }}>
          <Paper sx={{ 
              padding: '10px', 
              mb: 2,
              borderRadius: '8px', // Add border radius
              boxShadow: 3, // Add shadow
              '&:hover': {
                boxShadow: 6, // Increase shadow on hover
              }
            }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Difficulty</Typography>
            {difficulties.map((difficulty) => (
              <Chip
                key={difficulty}
                label={toPascalCase(difficulty)}
                variant={selectedDifficulties.includes(difficulty) ? "filled" : "outlined"}
                color={selectedDifficulties.includes(difficulty) ? "primary" : "default"}
                sx={{ 
                  mb: 1, 
                  borderRadius: '16px', // Add border radius to chips
                  width: '100%', 
                  '&:hover': {
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow on hover
                  }
                }}
                clickable
                onClick={() => handleDifficultyClick(difficulty)}
              />
            ))}
          </Paper>

          <Paper sx={{ 
              padding: '10px', 
              borderRadius: '8px', // Add border radius
              boxShadow: 3, // Add shadow
              '&:hover': {
                boxShadow: 6, // Increase shadow on hover
              }
            }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Topics</Typography>
            {uniqueTopics.map((topic) => (
              <Chip
                key={topic}
                label={toPascalCase(topic)}
                variant={selectedTopics.includes(topic) ? "filled" : "outlined"}
                color={selectedTopics.includes(topic) ? "primary" : "default"}
                sx={{ 
                  mb: 1, 
                  borderRadius: '16px', // Add border radius to chips
                  width: '100%', 
                  '&:hover': {
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow on hover
                  }
                }}
                clickable
                onClick={() => handleTopicClick(topic)}
              />
            ))}
          </Paper>
        </Box>}

        {/* Scrollable Problems Section */}
        <Box sx={{ flexBasis: { xs: '100%', sm: '75%' }, overflow: 'hidden', maxHeight: '80vh', position: 'relative' }}>
          <Paper component="form" sx={{ p: '2px 4px', mb: 3, display: 'flex', alignItems: 'center', width: 360 }}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Problem"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <IconButton type="submit" sx={{ p: '10px' }}>
              <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }}>
              <FilterListIcon />
            </IconButton>
          </Paper>

          <Button
            variant="contained"
            color="primary"
            onClick={handlePickForMe}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '150px',
              marginTop: '7px'
              // mb: 3,
            }}
          >
            Pick random
          </Button>

          <PerfectScrollbar>
            {filteredProblems.map((problem) => (
              <Paper
                sx={{ mb: 2, width: '98%', border: theme.palette.mode === 'dark' ? '1px solid white' : '' }}
                key={problem._id}
                className="group bg-white transition-all duration-1000 ease-in-out transform hover:scale-y-105 hover:shadow-lg hover:bg-gradient-to-r hover:from-blue-500 hover:to-blue-300"
              >
                <Link to={`/problems/${problem.slug}`}>
                  <div className="flex justify-between items-center cursor-pointer p-3">
                    <Typography variant="h7" sx={{ fontWeight: '' }} className="group-hover:text-white w-9/12 ">
                      {problem.title}
                    </Typography>
                    <div className="w-3/12 md:w-2/12 flex justify-between items-center">
                      <Button
                        variant="outlined"
                        color={problem.difficulty === 'easy' ? 'success' : problem.difficulty === 'medium' ? 'warning' : 'error'}
                        className="w-1/2 mr-1 group-hover:bg-white font-bold"
                      >
                        {problem.difficulty.charAt(0).toUpperCase() + problem.difficulty.slice(1)}
                      </Button>
                      {problem.isSolved ? <CheckCircleIcon className="text-green-700" /> : ''}
                    </div>
                  </div>
                </Link>
              </Paper>
            ))}
          </PerfectScrollbar>
        </Box>
      </Box>
</Box>); };

export default ProblemsPage;