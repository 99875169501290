import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Avatar, List, ListItem, Button, CircularProgress, Alert, useTheme } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import CodeIcon from '@mui/icons-material/Code';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js';
import { getToken } from '../common/utils';
import proxy from '../api/axios';
import { toast } from 'react-toastify';

// Register necessary components
ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const UserProfilePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {username} = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch user details from the API
  const fetchUserDetails = async () => {
    try {
      const token = getToken();
      if(username){
        const { data } = await proxy.get(`/users/${username}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if(!data.user){
          toast.error('No use exist with this username')
          navigate('/');
        }
        setUser(data.user);
      } else {
        const { data } = await proxy.get('/users', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(data.user);
      }
    } catch (err) {
      console.log('ddd', err);
      
      setError('Failed to load user data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [username]);

  const data = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Problems Solved Over Time',
        data: [3, 5, 2, 7],
        fill: false,
        backgroundColor: '#4caf50',
        borderColor: '#4caf50',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <span style={{marginLeft: 7, color: '#1497c1'}}>Loading User Profile</span>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: '20px' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', bgcolor: 'background.default', borderRadius: '12px' }}>
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center', color: '#1976d2' }}>
        User Profile
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, justifyContent: 'center' }}>
        <Avatar alt={user.name} src={user.image || 'https://via.placeholder.com/150'} sx={{ width: 100, height: 100, mr: 2 }} />
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {user.name}
          </Typography>
          <Typography variant="body1" sx={{ color: 'gray' }}>
            @{user.username} {/* Displaying username */}
          </Typography>
        </Box>
      </Box>

      <Paper sx={{ padding: '20px', bgcolor: 'background.paper', borderRadius: '12px', mb: 3, boxShadow: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Total Solved Problems: {user.solvedProblems.length}
        </Typography>
      </Paper>

      <Paper sx={{ padding: '20px', bgcolor: 'background.paper', borderRadius: '12px', mb: 3, boxShadow: 3 }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
          Solved Problems Over Time
        </Typography>
        <div style={{ position: 'relative', height: '200px' }}>
          <Line data={data} options={options} />
        </div>
      </Paper>

      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Problems Solved
      </Typography>
      <List sx={{ maxWidth:950 }}>
        {user.solvedProblems.map((problem) => (
          <ListItem key={problem.slug} sx={{ mb: 1 }}>
            <Button
              component={Link}
              to={`/problems/${problem.slug}`}
              variant="contained"
              sx={{
                width: '100%',
                bgcolor: theme.palette.mode === 'dark' ? '#333' : '#fff',
                color: theme.palette.mode === 'dark' ? '#fff' : '#333',
                justifyContent: 'flex-start',
                '&:hover': { bgcolor: '#115293',color: 'white' },
                borderRadius: '8px',
                padding: '10px',
                fontSize: '16px', // Increase font size for buttons
              }}
            >
              <CodeIcon sx={{ mr: 1 }} />
              {problem.title}
            </Button>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default UserProfilePage;
