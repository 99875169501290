// src/App.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import Navbar from './components/Navbar';
import ThemeSwitcher from './components/ThemeSwitcher';
import { Route, Routes } from 'react-router-dom';
import ProblemsPage from './pages/ProblemsPage';
import ProblemPage from './pages/ProblemPage';
import ProgramsPage from './pages/ProgramsPage';
import LeaderboardPage from './pages/LeaderboardPage';
import ContestsPage from './pages/ContestsPage';
import UserProfilePage from './pages/UserProfilePage';
import { Button } from '@mui/material';
import FeedbackForm from './pages/FeedbackFormModal';
import ProblemFormPage  from './pages/admin/ProblemFormPage ';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManageProblems from './pages/admin/ManageProblems';
import { login } from './redux/userSlice';
import ResetPassword from './components/auth/ResetPassword'
import Footer from './components/Footer';
import ManageContests from './pages/admin/ManageContests';
import LiveContest from './pages/LiveContest';
import ContestResult from './pages/ContestResult';
import ManageUsers from './pages/admin/ManageUsers';
import BookingPage from './pages/BookingPage';
// import InterviewSchedulePage from './pages/InterviewSchedulePage';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    if (token && user) {
      // Optionally validate the token with the server
      // const user = decodeToken(token); // If you have a way to decode the JWT
      dispatch(login(user)); // Set user as logged in
    }
  }, [dispatch]);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);

  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Navbar />
      <ThemeSwitcher />
      <ToastContainer />
      <div style={{ minHeight: '90vh' }}>
        <Routes>
          <Route path="/" element={<ProblemsPage/>} />
          <Route path="/problems" element={<ProblemsPage/>} />
          <Route path="/problems/:slug" element={<ProblemPage/>} />
          <Route path="/admin/problems/:slug" element={<ProblemPage isAdmin={true}/>} />
          <Route path="/dsa-programs" element={<ProgramsPage/>} />
          <Route path="/contests" element={<ContestsPage/>} />
          <Route path="/contests/:contestId/result" element={<ContestResult/>} />
          <Route path="/live-contest/:contestId" element={<LiveContest/>} />
          <Route path="/leaderboard" element={<LeaderboardPage/>} />
          <Route path="/user/:username" element={<UserProfilePage/>} />
          <Route path="/profile" element={<UserProfilePage/>} />
          <Route path="/schedule-mock-interviews" element={<BookingPage/>} />

          {/* Admin Routes */}
          <Route path="/admin/manage-problems" element={<ManageProblems/>} />
          <Route path="/admin/create-problem" element={<ProblemFormPage/>} />
          <Route path="/admin/manage-contests" element={<ManageContests/>} />
          <Route path="/admin/manage-users" element={<ManageUsers/>} />
          <Route path="/admin/edit-problem/:slug" element={<ProblemFormPage/>} />

          <Route path="/reset-password/:token" element={<ResetPassword/>} />

          <Route path="*" element={<ProblemsPage/>} />
        </Routes>
      </div>
      <Footer/>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleFeedbackOpen} 
        sx={{ position: 'fixed', bottom: 20, right: 20 }}
      >
        Feedback
      </Button>
      <FeedbackForm open={isFeedbackOpen} handleClose={handleFeedbackClose} />
    </ThemeProvider>
  );
};

export default App;
